<script setup>
  const props = defineProps({
    color: { default: 'primary', type: String },
    outline: { default: false, type: Boolean },
    size: { type: String }
  })
</script>

<template>
  <button
    :class="[
      'rounded border px-2 py-1 font-special font-bold leading-normal outline-none duration-300 focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-50',
      {
        'border-transparent bg-viking-400 text-tarawera-900 shadow focus-visible:ring-viking-400/50 hover:bg-viking-500':
          props.color === 'primary' && !props.outline,
        'border-viking-400 text-tarawera-900 shadow focus-visible:border-viking-500 focus-visible:bg-viking-500 focus-visible:ring-viking-400/50 hover:border-transparent hover:bg-viking-500':
          props.color === 'primary' && props.outline,
        'border-transparent bg-zinc-100 text-tarawera-900 shadow focus-visible:ring-zinc-200/50 hover:bg-zinc-200':
          props.color === 'secondary' && !props.outline,
        'border-zinc-100 text-tarawera-900 shadow focus-visible:border-zinc-100 focus-visible:ring-zinc-100/50 hover:border-transparent hover:bg-zinc-200':
          props.color === 'secondary' && props.outline,
        'border-transparent bg-red-500 text-white shadow focus-visible:ring-red-500/50 hover:bg-red-600': props.color === 'danger' && !props.outline,
        'border-red-500 text-red-500 shadow focus-visible:ring-red-500/50 hover:border-transparent hover:bg-red-600 hover:text-white':
          props.color === 'danger' && props.outline,
        'border-transparent underline decoration-viking-400 decoration-1 underline-offset-4 hover:decoration-viking-500': props.color === 'link',
        'rounded-sm px-1 py-0 text-xs': props.size === 'xs',
        'rounded-sm px-1 py-0.5 text-sm': props.size === 'sm',
        'rounded-md px-4 py-2 text-lg': props.size === 'lg'
      }
    ]"
  >
    <slot />
  </button>
</template>
