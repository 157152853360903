<script setup>
  import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Switch,
    SwitchGroup,
    SwitchLabel,
    ComboboxButton
  } from '@headlessui/vue'
  import { regionCities, regionCodes } from '~/data/search.json'

  const { filters, advancedTab, sectors, regions, employerTypes, sticky, createJobSearchUrl } = defineProps({
    sticky: {
      type: Boolean,
      default: true
    },
    advancedTab: {
      type: Boolean,
      default: true
    },
    filters: {
      type: Object,
      required: true,
      default: () => ({
        search: '',
        region: [],
        sector: [],
        employerType: [],
        teleworkable: false,
        management: false,
        type: false
      })
    },
    sectors: {
      type: Object,
      required: false,
      default: () => ({
        data: []
      })
    },
    regions: {
      type: Object,
      required: true,
      default: () => ({
        data: []
      })
    },
    employerTypes: {
      type: Object,
      required: false,
      default: () => ({
        data: []
      })
    },
    createJobSearchUrl: {
      type: Function,
      required: false,
      default: null
    }
  })

  // Input region maintenant utilise Combobox pour pouvoir rechercher une option dans le select:
  // - Recherche par code postal
  //   -> On récupère les codes postaux et leur région, on filtre les codes postaux qui incluent la recherche et on récupère les régions correspondantes
  // - Recherche par nom de ville
  //   -> On récupère les villes et leur région, on filtre les villes qui incluent la recherche et on récupère les régions correspondantes
  // - Recherche par nom de région insensible aux '-' et aux majuscules
  const regionQuery = ref('')
  const filteredRegion = computed(() => {
    if (regionQuery.value === '') return regions.data

    const query = regionQuery.value.toLowerCase()
    const filteredCodes = regionCodes.filter(region => region[0].includes(query)).map(region => region[1])
    const filteredCities = regionCities.filter(region => region[0].toLowerCase().includes(query)).map(region => region[1])

    return regions.data.filter(region => {
      const formattedSlug = region.slug.replace(/-/g, ' ').toLowerCase()
      return formattedSlug.includes(query) || filteredCodes.includes(region.name) || filteredCities.includes(region.name)
    })
  })

  const advancedSearch = ref(filters.employerType.length || filters.teleworkable || filters.management || filters.type)

  const { y } = useWindowScroll()
  const filtersElem = ref(null)

  const isSearchBig = computed(() => {
    return !sticky || y.value < 190
  })
</script>

<template>
  <div
    ref="filtersElem"
    :class="[
      ' w-full transition-[top] duration-300',
      isSearchBig ? 'bg-white md:top-20' : 'fixed bottom-0 z-10 rounded-t-xl bg-tarawera-900 shadow md:bottom-auto md:top-16 md:rounded-t-none'
    ]"
  >
    <div
      :class="[
        'mx-auto flex flex-col px-4 transition-none md:flex-row md:items-center md:justify-center',
        isSearchBig ? 'max-w-screen-lg space-y-8 py-8 md:space-y-0' : 'max-w-screen-xl py-0'
      ]"
    >
      <div :class="['self-center empty:hidden max-md:text-center md:mr-8 md:basis-[40%] lg:basis-[45%]', isSearchBig ? 'block' : 'hidden']">
        <slot name="title" />
      </div>

      <div
        :class="[
          'relative rounded-xl bg-tarawera-900 text-white duration-300',
          isSearchBig ? 'px-8 py-4 md:basis-[60%] lg:basis-[55%]' : 'md:basis-full'
        ]"
      >
        <Icon
          v-if="advancedTab"
          @click="advancedSearch = !advancedSearch"
          name="circle-down filled"
          size="xl"
          :class="[
            'absolute right-7 rounded-full bg-white text-viking-400 transition-[transform]  duration-300 hover:scale-105 hover:cursor-pointer',
            ,
            isSearchBig
              ? advancedSearch
                ? '-bottom-3 rotate-180'
                : '-bottom-3 rotate-0'
              : advancedSearch
                ? '-top-3 rotate-0 md:-bottom-3 md:top-auto md:rotate-180'
                : '-top-3 rotate-180 md:-bottom-3 md:top-auto md:rotate-0'
          ]"
        />
        <div :class="['-m-2 grid grid-cols-12 items-center pb-4', isSearchBig ? 'pt-4' : 'pt-6']">
          <div
            :class="[
              'relative order-1 col-span-12 m-2',
              isSearchBig ? 'sm:col-span-12' : advancedSearch ? 'sm:col-span-6' : 'hidden sm:col-span-6 md:block'
            ]"
          >
            <input
              class="form-input w-full"
              v-model="filters.search"
              placeholder="Votre prochain job ?"
              type="search"
              @keyup.enter="createJobSearchUrl ? navigateTo(createJobSearchUrl()) : ((advancedSearch = false), (y = 250))"
            />
            <div
              class="absolute right-8 top-1/2 -translate-y-1/2 transform space-x-1 text-sm text-zinc-400/75"
              v-if="createJobSearchUrl && filters.search.length"
            >
              <span>Entrer</span>
              <Icon class="translate-y-0.5" name="arrow-turn-down-left" size="sm" />
            </div>
          </div>

          <Combobox
            v-if="regions.data.length"
            v-model="filters.region"
            :class="[
              'relative order-2 col-span-12 m-2 text-tarawera-900',
              isSearchBig
                ? advancedSearch
                  ? 'sm:col-span-6'
                  : 'sm:col-span-8'
                : advancedSearch
                  ? 'sm:col-span-6 md:col-span-3'
                  : 'hidden sm:col-span-3 md:block'
            ]"
            as="div"
          >
            <ComboboxInput
              class="form-input w-full"
              placeholder="Où&nbsp;?"
              :displayValue="region => (filters.region ? region?.name : '')"
              @change="regionQuery = $event.target.value"
            />
            <div
              class="absolute right-10 top-1 h-5/6 w-8 cursor-pointer hover:text-viking"
              v-if="regionQuery !== '' || filters.region"
              @click="
                () => {
                  filters.region = undefined
                  regionQuery = ''
                }
              "
            >
              <Icon class="pl-2 pt-1.5" name="xmark" size="sm" />
            </div>
            <ComboboxButton class="form-select absolute right-1 top-1 h-5/6 w-9 items-center border-0"> </ComboboxButton>
            <ComboboxOptions
              class="absolute z-10 mt-0.5 max-h-64 w-full max-w-full overflow-auto rounded border border-zinc-300 bg-white shadow focus:border-viking-400 focus:outline-none focus:ring-1 focus:ring-viking-400"
            >
              <div class="cursor-default select-none truncate px-2 py-1" v-if="filteredRegion.length === 0 && regionQuery !== ''">
                <!-- Needs to be a div so class:select-none actually works -->
                Aucun résultat
              </div>

              <ComboboxOption
                class="cursor-default truncate px-2 py-1 hover:bg-zinc-100"
                v-for="region in filteredRegion"
                v-text="region.name"
                :class="{
                  'text-zinc-400': filters.region === region
                }"
                :key="region.slug"
                :value="region"
              />
            </ComboboxOptions>
          </Combobox>

          <slot name="cta" />

          <div
            v-if="sectors.data.length && advancedTab"
            v-show="advancedSearch"
            :class="['col-span-12 m-2', isSearchBig ? 'order-4 sm:col-span-6' : 'order-3 sm:col-span-6 lg:col-span-3']"
          >
            <Listbox class="relative text-tarawera-900" v-model="filters.sector" as="div" multiple>
              <ListboxButton class="form-select w-full" as="div">
                <div class="text-zinc-400" v-if="!filters.sector.length">Profession</div>
                <div class="-mx-1 -my-0.5 inline-flex flex-wrap" v-else>
                  <div
                    class="m-0.5 inline-flex cursor-pointer items-center space-x-1 whitespace-nowrap rounded-sm bg-viking-400 px-1 py-0.5 text-sm duration-300 hover:bg-viking-500"
                    v-for="item in filters.sector"
                    :key="item.slug"
                    @click.stop="filters.sector = filters.sector.filter(x => x.slug !== item.slug)"
                  >
                    <span>{{ item.name }}</span>
                    <Icon class="text-white" name="xmark" size="sm" />
                  </div>
                </div>
              </ListboxButton>
              <ListboxOptions
                class="absolute z-10 mt-0.5 max-h-64 w-full max-w-full overflow-auto rounded border border-zinc-300 bg-white shadow focus:border-viking-400 focus:outline-none focus:ring-1 focus:ring-viking-400"
              >
                <ListboxOption
                  class="cursor-default truncate px-2 py-1 hover:bg-zinc-100"
                  v-for="item in sectors.data"
                  v-text="item.name"
                  :class="{
                    'text-zinc-400': filters.sector.includes(item)
                  }"
                  :key="item.slug"
                  :value="item"
                />
              </ListboxOptions>
            </Listbox>
          </div>

          <div
            v-if="employerTypes.data.length"
            v-show="advancedSearch"
            :class="['order-4 col-span-12 m-2', isSearchBig ? 'sm:col-span-6' : 'sm:col-span-6 lg:col-span-3']"
          >
            <Listbox class="relative text-tarawera-900" v-model="filters.employerType" as="div" multiple>
              <ListboxButton class="form-select w-full" as="div">
                <div class="text-zinc-400" v-if="!filters.employerType.length">Type d’organisation</div>
                <div class="-mx-1 -my-0.5 inline-flex flex-wrap" v-else>
                  <div
                    class="m-0.5 inline-flex cursor-pointer items-center space-x-1 whitespace-nowrap rounded-sm bg-viking-400 px-1 py-0.5 text-sm duration-300 hover:bg-viking-500"
                    v-for="item in filters.employerType"
                    :key="item.slug"
                    @click.stop="filters.employerType = filters.employerType.filter(x => x.slug !== item.slug)"
                  >
                    <span>{{ item.name }}</span>
                    <Icon class="text-white" name="xmark" size="sm" />
                  </div>
                </div>
              </ListboxButton>
              <ListboxOptions
                class="absolute z-10 mt-0.5 max-h-64 w-full max-w-full overflow-auto rounded border border-zinc-300 bg-white shadow focus:border-viking-400 focus:outline-none focus:ring-1 focus:ring-viking-400"
              >
                <ListboxOption
                  class="cursor-default truncate px-2 py-1 hover:bg-zinc-100"
                  v-for="item in employerTypes.data"
                  v-text="item.name"
                  :class="{
                    'text-zinc-400': filters.employerType.includes(item)
                  }"
                  :key="item.slug"
                  :value="item"
                />
              </ListboxOptions>
            </Listbox>
          </div>

          <div
            v-show="advancedSearch"
            :class="[
              'order-5 flex',
              isSearchBig
                ? 'col-span-12 row-span-3 flex-col  sm:order-3 sm:col-span-6 sm:flex-col'
                : 'order-5 col-span-12 flex-col sm:col-span-6 sm:flex-row'
            ]"
          >
            <SwitchGroup class="m-2 flex items-center space-x-2" as="div">
              <Switch
                class="relative inline-flex h-6 w-10 shrink-0 items-center rounded-full outline-none duration-300 focus-visible:ring focus-visible:ring-viking-400"
                v-model="filters.teleworkable"
                :class="filters.teleworkable ? 'bg-viking-400' : 'bg-zinc-300'"
              >
                <span
                  class="inline-block h-4 w-4 rounded-full bg-white duration-300"
                  :class="filters.teleworkable ? 'translate-x-5' : 'translate-x-1'"
                />
              </Switch>
              <SwitchLabel class="truncate whitespace-nowrap">Télétravail possible</SwitchLabel>
            </SwitchGroup>

            <SwitchGroup class="m-2 flex items-center space-x-2" as="div">
              <Switch
                class="relative inline-flex h-6 w-10 shrink-0 items-center rounded-full outline-none duration-300 focus-visible:ring focus-visible:ring-viking-400"
                v-model="filters.management"
                :class="filters.management ? 'bg-viking-400' : 'bg-zinc-300'"
              >
                <span
                  class="inline-block h-4 w-4 rounded-full bg-white duration-300"
                  :class="filters.management ? 'translate-x-5' : 'translate-x-1'"
                />
              </Switch>
              <SwitchLabel class="truncate whitespace-nowrap">Management</SwitchLabel>
            </SwitchGroup>

            <SwitchGroup class="m-2 flex items-center space-x-2" as="div">
              <Switch
                class="relative inline-flex h-6 w-10 shrink-0 items-center rounded-full outline-none duration-300 focus-visible:ring focus-visible:ring-viking-400"
                v-model="filters.type"
                :class="filters.type ? 'bg-viking-400' : 'bg-zinc-300'"
              >
                <span class="inline-block h-4 w-4 rounded-full bg-white duration-300" :class="filters.type ? 'translate-x-5' : 'translate-x-1'" />
              </Switch>
              <SwitchLabel class="truncate whitespace-nowrap">Stage / Alternance</SwitchLabel>
            </SwitchGroup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
