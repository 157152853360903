import { notify } from 'notiwind'

export function atsSubscriptionMiddleware(types) {
  return function (to, from) {
    const ats = useAts()

    if (!ats.value.isSubscribed(types)) {
      if (process.client) {
        notify({ group: 'main', type: 'danger', title: 'Accès refusé', text: 'Vous n’avez pas les droits.' })
      }
      return navigateTo({ path: '/ats' })
    }
  }
}
