import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_kT77KznRYx from "/vercel/path0/node_modules/nuxt3-localforage/dist/runtime/plugin.mjs";
import plugin_8SbxDRbG6Y from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import strapi_CICqBBANNd from "/vercel/path0/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import plugin_client_i8AMfKeYnY from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import clipboard_client_xoN32NQ75x from "/vercel/path0/plugins/clipboard.client.js";
import fontawesome_klhsrycjcK from "/vercel/path0/plugins/fontawesome.js";
import html2pdf_client_9AThc3XOhw from "/vercel/path0/plugins/html2pdf.client.js";
import notiwind_yOq5nKgwUp from "/vercel/path0/plugins/notiwind.js";
import pdf_embed_client_ae2rxYuvz4 from "/vercel/path0/plugins/pdf-embed.client.js";
import popper_KwS8fMgSzV from "/vercel/path0/plugins/popper.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import vee_validate_K3WwmJMPDb from "/vercel/path0/plugins/vee-validate.js";
import vue_gtm_client_Cm24Dsc5BN from "/vercel/path0/plugins/vue-gtm.client.js";
import vue_mention_client_KAWd159YuU from "/vercel/path0/plugins/vue-mention.client.js";
import vue3_emoji_picker_client_WFeYWx1H2C from "/vercel/path0/plugins/vue3-emoji-picker.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_kT77KznRYx,
  plugin_8SbxDRbG6Y,
  strapi_CICqBBANNd,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  clipboard_client_xoN32NQ75x,
  fontawesome_klhsrycjcK,
  html2pdf_client_9AThc3XOhw,
  notiwind_yOq5nKgwUp,
  pdf_embed_client_ae2rxYuvz4,
  popper_KwS8fMgSzV,
  sentry_client_GoGQuZo4Et,
  vee_validate_K3WwmJMPDb,
  vue_gtm_client_Cm24Dsc5BN,
  vue_mention_client_KAWd159YuU,
  vue3_emoji_picker_client_WFeYWx1H2C
]