export default defineNuxtRouteMiddleware(to => {
  const config = useRuntimeConfig()
  const maintenance = false //config.public.env === 'production'
  const user = useStrapiUser()
  if (to.query.bypass || user.value) return

  if (maintenance && to.path !== '/maintenance') {
    return navigateTo({ path: '/maintenance' })
  }
})
