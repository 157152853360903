import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import career_45site_45global from "/vercel/path0/middleware/career-site.global.js";
import header_45sliding_45menu_45global from "/vercel/path0/middleware/header-sliding-menu.global.js";
import legacy_45redirects_45global from "/vercel/path0/middleware/legacy-redirects.global.js";
import maintenance_45global from "/vercel/path0/middleware/maintenance.global.js";
import scroll_45top_45global from "/vercel/path0/middleware/scroll-top.global.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  career_45site_45global,
  header_45sliding_45menu_45global,
  legacy_45redirects_45global,
  maintenance_45global,
  scroll_45top_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  ats: () => import("/vercel/path0/middleware/ats.js"),
  auth: () => import("/vercel/path0/middleware/auth.js")
}