import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

export default defineNuxtPlugin(nuxtApp => {
  const { vueApp } = nuxtApp
  const config = useRuntimeConfig()

  Sentry.init({
    app: [vueApp],
    dsn: config.public.sentry.dsn,
    environment: config.public.sentry.environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router)
      })
    ],
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      if (event.exception && config.public.sentry.environment != 'prod') {
        console.error(`[Exception handled by Sentry]: (${hint.originalException})`, { event, hint })
      }
      return event
    }
  })

  vueApp.mixin(
    Sentry.createTracingMixins({
      hooks: ['activate', 'mount', 'update'],
      timeout: 2000,
      trackComponents: true
    })
  )
  Sentry.attachErrorHandler(vueApp, {
    attachProps: true,
    hooks: ['activate', 'mount', 'update'],
    logErrors: false,
    timeout: 2000,
    trackComponents: true
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  }
})
