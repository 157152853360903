import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  routes: _routes => {
    const config = useRuntimeConfig()
    const host = useHost().value
    const { enabled } = useCareerSite()

    if (enabled.value && config.public.env !== 'development') {
      const routes = _routes.filter(route => typeof route.name === 'string' && route.name.includes('career'))
      return routes.forEach(route => (route.path = route.path.replace('/career', '')))
    }

    return _routes
  }
}
