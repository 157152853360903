<script setup>
  const props = defineProps({
    fixedWidth: { default: false, type: Boolean },
    name: { required: true, type: String },
    size: { type: String },
    type: { default: 'solid', type: String }
  })
</script>

<template>
  <FontAwesomeIcon :fixedWidth="props.fixedWidth" :icon="`fa-${props.type} fa-${props.name}`" :size="props.size" />
</template>
