import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()

  nuxtApp.vueApp.use(
    createGtm({
      id: config.public.gtmId || 'GTM-DEV',
      defer: false,
      compatibility: false,
      enabled: config.public.env === 'production',
      debug: false,
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false
    })
  )
})
