<script setup>
  const { find } = useStrapi4()
  const error = useError()
  const { query } = useRoute()

  const links = [
    { text: 'Retourner à la page d’accueil', href: '/' },
    { text: 'Aide', href: 'https://profil-public.zendesk.com/hc/fr' },
    { text: 'Trouvez un job', href: '/jobs' },
    { text: 'Découvrir les institutions', href: '/employeurs' },
    { text: 'Parcourir notre média', href: 'https://media.profilpublic.fr/je-minspire/' },
    { text: 'Nous signaler cette erreur', href: '/contact' }
  ]

  if (process.dev && error.value) console.error(error.value.message)

  // job error
  const regions = reactive({
    ...(await useLazyAsyncData(
      'regions',
      async () => {
        const res = await find('regions', {
          fields: ['name', 'slug'],
          pagination: { limit: 100, start: 0, withCount: false },
          sort: { name: 'asc' }
        })
        return res.data
      },
      {
        default: () => []
      }
    ))
  })

  const filters = reactive({
    region: query.localisation?.length ? regions.data.find(({ slug }) => query.localisation.split(',').includes(slug)) : undefined,
    search: query.recherche?.length ? query.recherche : '',
    sector: [],
    teleworkable: false,
    type: false,
    management: false,
    employerType: []
  })

  const createJobSearchUrl = () => {
    const suffix = '/jobs'
    const params = []
    if (filters.region) params.push(`localisation=${filters.region.slug}`)
    if (filters.search.length) params.push(`recherche=${filters.search}`)
    return suffix + (params.length ? '?' + params.join('&') : '')
  }

  const handleError = path => {
    clearError({ redirect: path })
  }

  useTitle(`Erreur ${error.value?.statusCode}`)
</script>

<template>
  <div class="flex min-h-screen justify-center" v-if="error">
    <div
      class="relative flex max-w-5xl items-center bg-zinc-100 p-4 font-main text-tarawera-900 max-md:w-full max-sm:text-center sm:p-8 md:m-auto md:mx-6 md:rounded-xl lg:p-12"
    >
      <a class="hover:cursor-pointer" @click="handleError('/')"
        ><NuxtImg
          class="absolute left-1/2 top-5 size-20 rounded-full max-md:-translate-x-1/2 md:-left-5 md:-top-5"
          src="/images/logo-pp-400x400.webp"
          alt="Logo Profil Public"
      /></a>
      <!-- employer not found -->
      <div class="mx-auto max-w-screen-xl items-center px-4 font-special text-xl font-bold sm:pt-4" v-if="error.url?.startsWith('/employeurs')">
        Oups, cette page n’existe plus. <br />
        Vous pouvez retourner à la <a class="underline decoration-viking-400 hover:cursor-pointer" @click="handleError('/')">page d’accueil.</a>
        <br /><br />
        Mais si c’est un employeur que vous cherchez, nous avons certainement
        <a class="underline decoration-viking-400 hover:cursor-pointer" @click="handleError('/employeurs')"> ce qu’il vous faut !</a>
      </div>

      <!-- job not found -->
      <div class="mx-auto max-w-screen-xl px-4 sm:pt-4" v-else-if="error.url?.startsWith('/jobs')">
        <div class="items-center font-special text-xl font-bold">
          Oups, cette page n’existe plus. <br />
          Vous pouvez retourner à la <a class="underline decoration-viking-400 hover:cursor-pointer" @click="handleError('/')">page d’accueil.</a>
          <br /><br />
          Mais si c’est un emploi que vous cherchez, nous avons certainement ce qu’il vous faut !
        </div>
        <JobSearchForm class="bg-zinc-100 text-center" :sticky="false" :advancedTab="false" :filters="filters" :regions="regions">
          <template v-slot:cta>
            <a @click="handleError(createJobSearchUrl())">
              <Button class="absolute -bottom-3 left-1/2 -translate-x-1/2 whitespace-nowrap" size="sm">
                <Icon name="magnifying-glass" type="regular" />
                <span class="ml-2">Voir les jobs</span>
              </Button>
            </a>
          </template>
        </JobSearchForm>
      </div>

      <div class="mx-auto flex max-w-screen-xl flex-col items-center px-2 xs:px-4 sm:pt-4 md:flex-row" v-else>
        <div class="grow max-xs:pt-24">
          <h2 class="mb-4 font-special text-xl font-bold">
            {{
              error.statusCode === 404
                ? 'La page que vous cherchez est introuvable (erreur 404)'
                : `Oups, une erreur est survenue (erreur ${error.statusCode})`
            }}
          </h2>
          <p class="mb-1 text-left font-special font-bold xs:text-lg">Plutôt que le bureau des renseignements, nous vous conseillons :</p>
          <ul class="text-left">
            <li v-for="link in links" :key="link.href">
              <a class="text-viking-400 duration-300 hover:cursor-pointer hover:text-viking-900" @click="handleError(link.href)">{{ link.text }}</a>
            </li>
          </ul>
        </div>
        <NuxtImg class="max-h-64 rounded-xl max-md:mb-0 max-md:mt-8 md:ml-8" src="/images/asterix.webp" alt="Erreur 404" />
      </div>
    </div>
  </div>
</template>
